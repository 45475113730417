import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { Instant } from "@js-joda/core";
import { MyMatDateTimePickerComponent } from "common";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: 'app-datetime-field',
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MyMatDateTimePickerComponent,
        MatIconModule,
    ],
    templateUrl: './datetime-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "class": "block",
    }
})
export class DatetimeFieldComponent {

    label = input.required<string>();
    control = input.required<FormControl<Instant | null>>();
    required = input(false);
    icon = input(true);
}
