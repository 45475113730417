import { PilotageRouteInfo } from "apina-frontend";
import { createTextMatcher, partition } from "common";

export function routeDescription(route: PilotageRouteInfo): string {
    return `${route.start.code}–${route.end.code}`;
}

export function filterRoutesOrChains<T extends FilterableRouteOrChain>(query: string, items: readonly T[]): T[] {
    // Both the query and route names might contain dashes to separate different parts.
    // We wish to consider those as separate tokens.
    const normalize = (s: string): string => s.replace('-', ' ').toLowerCase();

    const normalizedQuery = normalize(query);
    const queryParts = normalizedQuery.split(/\s+/);

    // Special case: query has two locodes: match directly against start and end codes
    if (queryParts.length === 2 && queryParts[0].startsWith("fi") && queryParts[1].startsWith("fi")) {
        return items.filter(it => it.startCode.toLowerCase().startsWith(queryParts[0]) && it.endCode.toLowerCase().startsWith(queryParts[1]));
    }

    const matcher = createTextMatcher(normalizedQuery);

    const matches = items.filter(it => matcher([normalize(it.name), it.startCode, it.startName, it.endCode, it.endName]));
    if (queryParts.length !== 0) {
        // Prioritize matches so that we always start with the elements where first term matches start code or name
        const firstTermMatcher = createTextMatcher(queryParts[0]);

        const [best, rest] = partition(matches, it => firstTermMatcher([it.startCode, it.startName]));
        return [...best, ...rest];
    }

    return matches;
}

interface FilterableRouteOrChain {
    name: string;
    startCode: string;
    endCode: string;
    startName: string;
    endName: string;
}
