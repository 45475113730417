import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractMatFormFieldControl, IMO, MMSI } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, merge, Observable, of as observableOf } from "rxjs";
import { debounceTime, distinctUntilChanged, map, skip, switchMap, takeUntil } from "rxjs/operators";
import { MatFormFieldControl } from "@angular/material/form-field";
import { VesselEndpoint, VesselId } from "apina-frontend";
import { MatAutocomplete, MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { AsyncPipe } from "@angular/common";

@Component({
    selector: "app-select-vessel",
    templateUrl: "./select-vessel.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: MatFormFieldControl, useExisting: SelectVesselComponent},
    ],
    imports: [
        AsyncPipe,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
    ],
    host: {
        '(focus)': 'focus()'
    }
})
export class SelectVesselComponent extends AbstractMatFormFieldControl<VesselId> implements AfterViewInit {

    private static nextId = 0;

    readonly control = new FormControl<string>("", {nonNullable: true});
    readonly filteredVessels$: Observable<FilterResults | null>;

    private readonly selectedVesselId = new BehaviorSubject<VesselId | null>(null);

    /** Store the last loaded/selected vessel because that will probably be needed again */
    private cachedVesselData: VesselData | null = null;

    @Input()
    allowNewVessel = false;

    @Input()
    showVesselClass = true;

    @Output()
    readonly createNewVessel = new EventEmitter<void>();

    @ViewChild('inputField') inputField!: ElementRef;

    @ViewChild('vesselAutoComplete') vesselAutoComplete?: MatAutocomplete;

    /**
     * Is the autocomplete currently open?
     *
     * We'd like to use vesselAutoComplete.isOpen, but it still reports "open" when closing events arrive,
     * so we need to track state manually.
     */
    private _autoCompleteOpen = false;

    get value(): VesselId | null {
        return this.selectedVesselId.value;
    }

    set value(id: VesselId | null) {
        if (id !== this.selectedVesselId.value) {
            this.selectedVesselId.next(id);
            this.updateVesselName();
            this.stateChanges.next();
        }
    }

    constructor(private readonly vesselEndpoint: VesselEndpoint) {
        super("app-select-vessel", SelectVesselComponent.nextId++);

        const valueChanges: Observable<string> = this.control.valueChanges;

        this.filteredVessels$ = valueChanges.pipe(
            debounceTime(50),
            switchMap(v => v.length === 0 ? observableOf({vessels: null})
                : vesselEndpoint.findVessels(v, 10).then(vessels => ({vessels}))));

        this.stateChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            if (!this.focused)
                this.updateVesselName();
        });
    }

    ngAfterViewInit(): void {
        merge(this.vesselAutoComplete!.opened.pipe(map(() => true)), this.vesselAutoComplete!.closed.pipe(map(() => false))).pipe(takeUntil(this.componentDestroyed$)).subscribe(open => {
            this._autoCompleteOpen = open;
            this.stateChanges.next();
        });
    }

    protected override get hasCustomFocus(): boolean {
        return this._autoCompleteOpen;
    }

    protected override onDisabled(disabled: boolean): void {
        if (disabled)
            this.control.disable();
        else
            this.control.enable();
    }

    focus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.focus();
    }

    fieldReceivedFocus(): void {
        const input = this.inputField.nativeElement as HTMLInputElement;
        input.select();
    }

    selectVessel(vessel: VesselData | "new-vessel" | null): void {
        if (vessel === "new-vessel") {
            this.createNewVessel.next();
        } else if (vessel != null) {
            this.cachedVesselData = vessel;
            this.value = vessel.id;
            this.control.setValue(vessel.name);
        } else {
            this.cachedVesselData = vessel;
            this.value = null;
            this.control.setValue("");
        }
    }

    private async updateVesselName(): Promise<void> {
        const id = this.value;
        if (id != null) {
            try {
                const vessel = await this.findVessel(id);
                this.cachedVesselData = vessel;
                this.control.setValue(vessel.name);
            } catch (e) {
                console.error("failed to load vessel", e);
            }
        } else {
            this.control.setValue("");
        }
    }

    private async findVessel(id: VesselId): Promise<VesselData> {
        const cached = this.cachedVesselData;
        if (cached?.id === id) {
            return cached;
        } else {
            return this.vesselEndpoint.findVesselById(id);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registerOnChange(fn: any): void {
        // BehaviorSubject broadcasts the initial value, we skip it to avoid sending invalid change event
        this.selectedVesselId.pipe(skip(1), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe(fn);
    }
}

/**
 * We receive FindVesselDtos and VesselDetails from server, depending on the call. This interface describes
 * the data common to them that we are actually interested in.
 *  */
interface VesselData {
    id: VesselId;
    name: string;
    callSign: string | null;
    imo: IMO | null;
    mmsi: MMSI | null;
    vesselClass: string | null;
}

interface FilterResults {

    /** Null for no query made, empty list for no matching vessels */
    vessels: readonly VesselData[] | null;
}
